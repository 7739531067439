/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import media from '../../shared/mediaQuery';

type RadiobuttonProps = {
  checked: boolean;
  name?: string;
  theme: 'orange';
  label?: string;
  clickHandler?: (event?: any) => void;
  value: string;
  id: string;
  disabled?: boolean;
};

const Radiobutton = ({
  name,
  theme,
  label = '',
  id = '',
  clickHandler,
  value = '',
  checked = false,
  disabled = false,
}: RadiobuttonProps) => {
  return (
    <div css={[style, themes[theme]]}>
      <input
        id={id}
        type="radio"
        className="radiobutton--element"
        name={name}
        onClick={clickHandler}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      {label.length > 0 && (
        <label className="radiobutton--label" htmlFor={`${id}`}>
          {label}
        </label>
      )}
    </div>
  );
};

Radiobutton.defaultProps = {
  theme: 'orange',
};

const style = css`
  .radiobutton--element {
    width: 24px;
    height: 24px;
    -webkit-appearance: none;
    background-color: #ffffff;
    border: 1px solid #cfcfcf;
    display: inline-block;
    position: relative;
    border-radius: 100%;
    cursor: pointer;

    &:active,
    &:checked:active,
    &:focus {
      outline: none;
    }

    &:checked {
      width: 24px;
      height: 24px;
    }
    &:after {
      position: absolute;
      top: 7px;
      left: 7px;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #cfcfcf;
    }
  }
  .radiobutton--label {
    font-size: 16px;
    color: #555555;
    vertical-align: top;
    display: inline-block;
    margin-left: 4px;
    ${media.mobile} {
      margin-top: 1px;
    }
    ${media.desktop} {
      font-size: 16px;
    }
  }
`;
const themes = {
  orange: css`
    .radiobutton--element {
      &:checked {
        border: 1px solid #ff5500;
      }
      &:checked:after {
        background-color: #ff5500;
      }
    }
  `,
};
export default Radiobutton;
