import styled from '@emotion/styled';
import media from '@shared/mediaQuery';

const QuestionActionPopupStyle = styled('div')`
  background-color: #ffffff;
  ${media.mobile} {
    width: calc(100% - 40px);
    padding-top: 23px;
    margin: 95px auto;
  }
  ${media.desktop} {
    width: 673px;
    margin: 213px auto 0;
    padding-top: 40px;
  }
  .questionpopup {
    &--title {
      font-family: 'Pretendard-Bold';
      text-align: center;
      font-size: 22px;
      ${media.desktop} {
        font-size: 24px;
      }
    }
    &--desc {
      font-size: 14px;
      color: #757575;
      text-align: center;
      margin-top: 7px;
    }
    &--selectwrap {
      margin-top: 28px;
      display: flex;
      flex-wrap: wrap;
      ${media.mobile} {
        padding: 0 20px;
      }
      ${media.desktop} {
        width: 577px;
        margin: 30px auto 0;
        justify-content: space-between;
      }
    }
    &--select {
      margin-right: 12px;
      margin-bottom: 5px;
    }
    &--body {
      border-top: 1px solid #e0e0e0;
      margin: 8px auto 0;
      ${media.mobile} {
        width: calc(100% - 40px);
      }
      ${media.desktop} {
        width: 577px;
      }
      &-text {
        width: 100%;
        height: 35px;
        resize: none;
        padding: 4px 0;
        line-height: 20px;
        border: none;
        font-size: 14px;
        ${media.desktop} {
          height: 159px;
        }
        &::placeholder {
          font-size: 14px;
          color: #999999;
        }
        &.phone {
          height: 23px;
        }
      }
      &-editor {
        ${media.mobile} {
          padding: 16px 19px 11px;
        }
        ${media.desktop} {
          padding: 19px 19px 11px;
        }
        &.phone {
          ${media.desktop} {
            padding-bottom: 5px;
          }
        }
      }
      &-header {
        display: flex;
        justify-content: space-between;
        &-title {
          font-size: 14px;
          color: #333333;
          font-family: 'Pretendard-Bold';
          ${media.desktop} {
            font-size: 16px;
          }
        }
        &-length {
          font-size: 12px;
          color: #aaaaaa;
        }
      }
      &-editor {
        border: 1px solid #e0e0e0;
        border-top: none;
      }
    }
    &--individual {
      display: flex;
      margin-top: 13px;
      margin-left: 2px;
      ${media.desktop} {
        margin-left: 26px;
      }
      .checkbox--label {
        font-size: 12px;
        margin-top: 0px;
      }
      &-wrap {
        padding: 0 21px;
      }
      &-button {
        font-family: 'Pretendard-Bold';
        font-size: 12px;
        color: #757575;
        margin-left: 5px;
        margin-top: -4px;
        ${media.desktop} {
          margin-left: 9px;
        }
      }
      &-table {
        display: flex;
        justify-content: space-between;
        background-color: #f6f6f6;
        margin-top: 10px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.8s ease-out;
        ${media.desktop} {
          width: 577px;
          margin: 10px auto 0;
        }
        &.show {
          max-height: 500px;
          transition: max-height 0.8s ease-out;
        }
        &-column {
          ${media.mobile} {
            min-width: 33.33%;
          }
          ${media.desktop} {
            width: 193px;
          }
        }
        &-header {
          text-align: center;
          background-color: #e0e0e0;
          font-size: 11px;
          color: #757575;
          font-family: 'Pretendard-Bold';
          padding: 3px 0;
          ${media.desktop} {
            font-size: 12px;
          }
        }
        &-body {
          font-size: 11px;
          color: #999999;
          text-align: center;
          padding: 8px 0;
          ${media.desktop} {
            padding: 7px 0;
            font-size: 12px;
          }
        }
      }
    }
    &--buttons {
      display: flex;
      margin-top: 11px;
      ${media.desktop} {
        margin-top: 22px;
      }
    }
    &--button {
      font-family: 'Pretendard-Bold';
      font-size: 15px;
      flex: none;
      height: 40px;
      ${media.desktop} {
        height: 48px;
      }
      &.cancle {
        background-color: #f6f6f6;
        width: 116px;
        color: #555555;
        ${media.desktop} {
          width: 233px;
        }
      }
      &.complete {
        background-color: #333333;
        color: #ffffff;
        ${media.desktop} {
          width: calc(100% - 233px);
        }
        ${media.mobile} {
          width: calc(100% - 116px);
        }
      }
    }
  }
`;

export default QuestionActionPopupStyle;
