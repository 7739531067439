import http from '../utils/http-common';

const sendEmailAdress = (email: string) => {
  return http.axiosCreate.post(`/ques/ad`, { email });
};

const sendQuestion = (data: { type: string; phone: string; msg: string }) => {
  return http.axiosCreate.post(`/ques`, { data });
};

export default {
  sendEmailAdress,
  sendQuestion,
};
