import { FunctionComponent, useState, useEffect } from 'react';
import QuestionActionPopupWrapper from './questionaction.style';
import Radiobox from '@element/Radiobox';
import Checkbox from '@element/Checkbox/Checkbox';
import AdsService from '@service/adsService';
import { Toast } from '@component/Toast';
import Alert from '@component/Alert';

type QuestionActionPopupType = {
  clickCancle: () => void;
};

const SIGNUP = '변호사 가입';
const SOLUTION = '로톡솔루션';
const ADS = '로톡Ads(광고)';
const ETC = '기타';

const QuestionactionPopup: FunctionComponent<QuestionActionPopupType> = ({ clickCancle }) => {
  const [checked, setChecked] = useState(false);
  const [showIndividual, setShowIndividual] = useState(false);
  const [sendData, setSendData] = useState({ type: '', phone: '', msg: '' });
  const [toastMessage, setToastMessage] = useState('');
  const alertResetdata = { open: false, description: '' };
  const [visibleAlert, setVisibleAlert] = useState(alertResetdata);
  const [isComplete, setIsComplete] = useState(false);

  const selectQuestionType = (e: any) => {
    setSendData({ ...sendData, type: e.currentTarget.value });
  };
  const agreeIndividual = () => {
    setChecked(!checked);
  };
  const openIndividual = () => {
    setShowIndividual(!showIndividual);
  };

  const clickConfirm = () => {
    setVisibleAlert(alertResetdata);
    clickCancle();
  };
  const completeQuestion = async () => {
    if (sendData.type.length === 0) {
      setToastMessage('문의유형을 선택해주세요');
      return;
    }
    if (sendData.msg.length === 0) {
      setToastMessage('문의내용을 작성해주세요');
      return;
    }

    if (sendData.phone.length === 0) {
      setToastMessage('연락처를 입력해주세요');
      return;
    }
    const isPhone = /(^02.{0}|^01.{1}|0.[0-9])([0-9]+)([0-9]{4})/g.test(sendData.phone);
    if (!isPhone) {
      setToastMessage('올바른 연락처를 입력해주세요');
      return;
    }

    if (!checked) {
      setToastMessage('개인정보 수집/이용동의가 필요합니다');
      return;
    }

    try {
      if (ADS === sendData.type) {
        sendData.type = '광고';
      }
      const result = await AdsService.sendQuestion(sendData);
      if (result.status === 200) {
        setIsComplete(true);
        setVisibleAlert({ open: true, description: '문의 신청이 완료되었습니다.' });
        return;
      } else {
        throw Error();
      }
    } catch (error) {
      console.error(error);
      setVisibleAlert({ open: true, description: '전송해 실패하였습니다 잠시후 다시 시도해 주세요.' });
      return;
    }
  };

  const changeContents = (e: any) => {
    setSendData({ ...sendData, msg: e.currentTarget.value });
  };

  const changePhone = (e: any) => {
    const phoneValue = e.currentTarget.value;

    setSendData({ ...sendData, phone: phoneValue.toString().replace(/[^0-9]/g, '') });
  };

  useEffect(() => {
    if (toastMessage.length > 0) {
      setTimeout(() => {
        setToastMessage('');
      }, 1000);
    }
  }, [toastMessage]);

  return (
    <QuestionActionPopupWrapper>
      <Toast message={toastMessage} visible={toastMessage.length > 0} />
      <p className="questionpopup--title">문의하기</p>
      <p className="questionpopup--desc">입력해주신 연락처로 담당자가 연락드립니다.</p>
      <div className="questionpopup--selectwrap">
        <div className="questionpopup--select">
          <Radiobox
            id="signup"
            label={`${SIGNUP}문의`}
            clickHandler={selectQuestionType}
            value={SIGNUP}
            checked={sendData.type === SIGNUP}
          />
        </div>
        <div className="questionpopup--select">
          <Radiobox
            id="solution"
            label={`${SOLUTION} 문의`}
            clickHandler={selectQuestionType}
            value={SOLUTION}
            checked={sendData.type === SOLUTION}
          />
        </div>
        <div className="questionpopup--select">
          <Radiobox
            id="ads"
            label={`${ADS} 문의`}
            clickHandler={selectQuestionType}
            value={ADS}
            checked={sendData.type === ADS}
          />
        </div>
        <div className="questionpopup--select">
          <Radiobox
            id="etc"
            label={`${ETC} 문의`}
            clickHandler={selectQuestionType}
            value={ETC}
            checked={sendData.type === ETC}
          />
        </div>
      </div>
      <div className="questionpopup--body">
        <div className="questionpopup--body-editor">
          <div className="questionpopup--body-header">
            <p className="questionpopup--body-header-title">문의내용</p>
            <span className="questionpopup--body-header-length">{sendData.msg.length}/200</span>
          </div>
          <textarea
            className="questionpopup--body-text"
            placeholder="궁금하신 내용을 간단하게 작성해주세요"
            maxLength={200}
            onChange={changeContents}
          />
        </div>
        <div className="questionpopup--body-editor phone">
          <div className="questionpopup--body-header">
            <p className="questionpopup--body-header-title">연락처</p>
          </div>
          <input
            className="questionpopup--body-text phone"
            placeholder="-없이 입력해주세요."
            onChange={changePhone}
            value={sendData.phone}
            type="text"
          />
        </div>
      </div>
      <div className="questionpopup--individual-wrap">
        <div className="questionpopup--individual">
          <Checkbox checked={checked} label="개인정보수집동의" clickHandler={agreeIndividual} />
          <button className="questionpopup--individual-button" onClick={openIndividual}>
            {showIndividual ? '내용접기' : '내용보기'}
          </button>
        </div>
        <div className={`questionpopup--individual-table ${showIndividual && 'show'}`}>
          <div className="questionpopup--individual-table-column">
            <p className="questionpopup--individual-table-header">수집이용 목적</p>
            <p className="questionpopup--individual-table-body">
              상품/서비스
              <br /> 문의 안내 및 설명
            </p>
          </div>
          <div className="questionpopup--individual-table-column">
            <p className="questionpopup--individual-table-header">수집항목</p>
            <p className="questionpopup--individual-table-body">연락처</p>
          </div>
          <div className="questionpopup--individual-table-column">
            <p className="questionpopup--individual-table-header">보유기간</p>
            <p className="questionpopup--individual-table-body">
              목적달성 15일 후 <br />
              파기
            </p>
          </div>
        </div>
      </div>
      <div className="questionpopup--buttons">
        <button className="questionpopup--button cancle" onClick={clickCancle}>
          취소
        </button>
        <button className="questionpopup--button complete" onClick={completeQuestion}>
          문의 신청 완료
        </button>
      </div>
      {visibleAlert.open && (
        <div id={`questionpopup--${isComplete ? 'complete' : 'notyet'}-alert`}>
          <Alert title="문의하기" description={visibleAlert.description} clickConfirm={clickConfirm} />
        </div>
      )}
    </QuestionActionPopupWrapper>
  );
};

export default QuestionactionPopup;
