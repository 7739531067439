/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FunctionComponent, useState, useEffect } from 'react';
import QuestionActionPopup from './QuestionActionPopup';
import media from '@shared/mediaQuery';

const Questionaction: FunctionComponent = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const clickQuestion = () => {
    const isOpen = !openPopup;
    setOpenPopup(isOpen);

    if (isOpen) {
      document.body.classList.add('body-scroll-ban');
    } else {
      document.body.classList.remove('body-scroll-ban');
    }
  };

  const getClientRectTop = (target: any) => {
    return target?.getBoundingClientRect()?.top;
  };

  const useScroll = () => {
    const [state, setState] = useState({ footerHeight: 0 });
    const onScroll = () => {
      const lastElem = document.getElementsByClassName('footer-container')[0];
      const relaLastElemTop = getClientRectTop(lastElem);
      // @ts-ignore: Unreachable code error
      const lastElmentCalc = relaLastElemTop + lastElem?.offsetHeight;

      const viewportHeight = Math.max(
        window.innerHeight || 0,
        (document.documentElement && document.documentElement.clientHeight) || 0,
      );

      setState({ footerHeight: lastElmentCalc - viewportHeight });
    };
    useEffect(() => {
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }, []);
    return state;
  };

  const { footerHeight } = useScroll();

  return (
    <div css={[style]}>
      <button className={`questionaction--button ${footerHeight > 208 && 'visible'}`} onClick={clickQuestion}>
        문의
        <br />
        하기
      </button>
      {openPopup && (
        <div className="questionaction--popup-wrap">
          <QuestionActionPopup clickCancle={clickQuestion} />
        </div>
      )}
    </div>
  );
};
const style = css`
  .questionaction {
    &--button {
      z-index: 5;
      position: fixed;
      bottom: -52px;
      right: 20px;
      box-shadow: 3px 3px 13px -1px rgba(51, 51, 51, 0.3);
      width: 52px;
      height: 52px;
      border-radius: 100%;
      background-color: #333333;
      color: #ffffff;
      font-family: 'Pretendard-Bold';
      font-size: 13px;
      line-height: 16px;
      ${media.desktop} {
        right: calc(50% - 600px);
      }
      &.visible {
        bottom: 44px;
      }
    }
    &--popup-wrap {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba(34, 34, 34, 0.7);
      overflow-y: auto;
    }
  }
`;
export default Questionaction;
